import React, { Component, useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import rightArrow from "../../images/carousel-arrow.svg";
import leftArrow from "../../images/carousel-left-arrow.svg";
import ReactGA from "react-ga";
import ReactMarkdown from "react-markdown";
import Modal from "react-bootstrap/Modal";
import left from "../../images/left.png";
import right from "../../images/right.png";
import ReactPlayer from "react-player";

const StyledWrapper = styled.div`
  .slider-slick {
    max-width: ${props => props.theme["percent-90"]};
    margin: auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
    border: 0.25px solid rgba(151, 151, 151, 0.5);
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .img-class {
    height: 20rem;
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    margin: 0 auto;
  }
  .slick-prev {
    left: -9.7%;
    z-index: 1;
    background-color: black;
  }

  .slick-next {
    right: -9.7%;
    z-index: 1;
    background-color: black;
  }

  .slick-next:hover,
  .slick-prev:hover {
    background-color: black;
  }

  .slick-prev:before {
    content: "";
  }

  .slick-next:before {
    content: "";
  }

  .custom-arrow {
    width: 17px;
    height: 29px;
    margin-bottom: 0px;
  }

  .item-name {
    color: white;
    font-size: 24px;
    font-weight: 900;
    font-size: 1rem;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .item-description {
    color: white;
    font-size: 17px;
    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  .overlay {
    margin-top: -135px;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    height: 135px;
    left: 2%;
    width: 95.7%;
    padding-top: 15px;

    @media (max-width: 600px) {
      left: 4%;
      width: 92%;
      margin-top: -110px;
      height: 110px;
      padding-top: 0px;
    }

    @media (min-width: 601px) and (max-width: 768px) {
      left: 1.5%;
      width: 97%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      left: 2.5%;
      width: 95%;
      margin-top: -150px;
      height: 150px;
    }
  }
  .card {
    background-color: #f7f7f7;
    border-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-style: none;
    outline: none !important;
  }
  .card::after {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: none !important;
  }
  .card::before {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: none !important;
  }
  .card:focus {
    outline: none !important;
  }

  .subcard {
    margin: 1rem;
    @media screen and (min-width: 280px) and (max-width: 320px) {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 410px;
      display: block;
      margin: 0 auto;
      white-space: nowrap;
    }
  }
  .subtext {
    display: block;
    height: 6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .item-description {
    padding: 0px;
    width: 100%;
    font-size: 0.9rem;
    white-space: initial;

    @media screen and (max-width: 280px) {
      font-size: 0.7rem;
    }
  }
  .readmore {
    color: white;
    font-size: 1.3rem;
    text-decoration: underline;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    cursor: pointer;
  }
`;
const StyledModal = styled(Modal)`
  .btn-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow-img {
    width: 3rem;
    height: 3rem;
    @media screen and (max-width: 991px) {
      width: 20px;
      height: 20px;
    }
    @media screen and (max-width: 320px) {
      width: 17px;
      height: 17px;
    }
  }
  .desc {
    margin-left: 0.1rem;
    margin-bottom: 3rem;
  }
  .arr-btn {
    background-color: #fff;
    border-style: none;
  }
  .modal-desc {
    height: 600px;
    overflow: scroll;
    @media screen and (max-width: 360px) {
      font-size: 0.8rem;
      height: 400px;
    }
    @media screen and (max-width: 320px) {
      font-size: 0.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      height: 600px;
    }
  }
  .modal {
    width: 10px;
  }
  .read-more-para {
    cursor: pointer;
  }
`;

function NextArrow(props) {
  const { className, style, onClick } = props;
  const right = useRef(null);
  useEffect(() => {
    right.current.onclick = function (event) {
      ReactGA.event({
        category: "Homepage",
        action: "Carousel Right Button Click",
      });
    };
  }, [NextArrow]);
  return (
    <div
      className={className}
      ref={right}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img src={rightArrow} className="custom-arrow" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  const left = useRef(null);
  useEffect(() => {
    left.current.onclick = function (event) {
      ReactGA.event({
        category: "Homepage",
        action: "Carousel Left Button Click",
      });
    };
  }, [PrevArrow]);
  return (
    <div
      className={className}
      ref={left}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img src={leftArrow} className="custom-arrow" />
    </div>
  );
}
function Storycarousel({ data }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState("");
  const [modalHeading, setModalHeading] = React.useState("");
  const [modalImage, setModalImage] = React.useState("");
  const [count, setCount] = useState(0);
  const [selectedTabByHandle, setSelectedTabByHandle] = useState("");
  const unique = [...new Set(data.map(i => i.heading))];
  const mainUrl = process.env.GATSBY_API_URL;
  const arr = [];
  data.map(item => {
    arr.push({
      name: item.heading,
      description: item.description,
      image: item.image !== null ? mainUrl + item.image.url : "",
      url: item.YoutubeUrl,
    });
  });

  const incrementValue = () => {
    if (count >= arr.length - 1) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  const decrementValue = () => {
    if (count <= 0) {
      setCount(arr.length - 1);
    } else {
      setCount(count - 1);
    }
  };

  var settings = {
    speed: 500,
    slidesToShow: 4,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    accessibilty: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          infinity: true,
          slidesToScroll: 3,
          arrows: true,
          accessibilty: true,
        },
      },
      {
        breakpoint: 691,
        settings: {
          slidesToShow: 1,
          arrows: false,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: true,
          centerMode: true,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <StyledWrapper className="padding-left-8 padding-right-8 container-fluid">
      <Slider {...settings} className="slider-slick">
        {data.map(item => (
          <div className="my-3 card">
            <div
              className="subcard"
              onClick={() => {
                window.location.hash = "stories";
                setSelectedTabByHandle(item.heading);
              }}
              style={{ backgroundColor: `${item.color}` }}
            >
              {item.image && (
                <img
                  src={mainUrl + item.image.url}
                  className="img-class img-fluid"
                  alt=""
                />
              )}

              <div className="subtext">
                <span className="item-name">{item.heading}</span>
                <p className="item-description">{item.brief}</p>
              </div>
              <p
                className="read-more-para"
                onClick={() => {
                  setModalOpen(true);
                  setModalData(item.description);
                  setModalHeading(item.heading);
                  setCount(arr.indexOf(arr.find(e => e.name === item.heading)));
                  item.image && setModalImage(mainUrl + item.image.url);
                }}
              >
                <h2 className="readmore">Read more</h2>
              </p>
            </div>
          </div>
        ))}
      </Slider>

      <StyledModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{arr[count].name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-1 col-sm-1 col-md-1 btn-align">
              <button onClick={decrementValue} className="arr-btn">
                <img src={left} className="arrow-img" />
              </button>
            </div>
            <div className="col-4 col-sm-4 col-md-3">
              <img
                src={arr[count].image}
                className="img-fluid"
                height="100%"
                width="100%"
              />
            </div>
            <div className="col-5 col-sm-6 col-md-7 modal-desc">
              {arr[count].url != null && (
                <ReactPlayer className="desc" url={arr[count].url} />
              )}

              <ReactMarkdown source={arr[count].description} />
            </div>
            <div className="col-1 col-sm-1 col-md-1 btn-align">
              <button onClick={incrementValue} className="arr-btn">
                <img src={right} className="arrow-img" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </StyledWrapper>
  );
}

export default Storycarousel;
