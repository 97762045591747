import React, { useState, useEffect } from "react";
import axios from "axios";
import Accordion from "../components/About/Accordion";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ImageCarousel from "../components/Homepage/ImageCarousel";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import AwardAccordion from "../components/PreviousWinners/AwardAccordion";
import styled from "styled-components";
import ReactGA from "react-ga";
import Storycarousel from "../components/Reusables/Storycarousel";
import ImageLink from "../components/Reusables/ImageLink";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .bottom-div {
    color: ${props => props.theme["borderBottomColor"]};
  }
  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
`;

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Winners />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/previous-winners`);
};
const fetchStories = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stories`);
};
const Winners = () => {
  const [newData, setNewData] = useState(null);
  const [stories, setStories] = useState(null);
  const [onscroll, setOnscroll] = useState(false);
  const { data: pageData } = useQuery("winners", fetchData);
  const { data: story } = useQuery("stories", fetchStories);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (story !== undefined) {
      setStories(story.data);
    }
  }, [pageData, story]);
  const handleClick = () => {
    ReactGA.event({
      category: "Previous Winners Page",
      action: "Get in Touch Button Click",
    });
  };
  useEffect(() => {
    if (document.location.hash === "#stories") {
      setOnscroll(true);
    } else if (document.location.hash === "") {
      setOnscroll(false);
    } else {
      setOnscroll(false);
    }
  }, []);
  if (onscroll === true) {
    setTimeout(() => {
      document
        .querySelector("#stories")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}
        {newData && newData.showImageCarousel && (
          <div className="container-fluid py-5">
            {newData.previouseWinners.length > 0 && (
              <ImageLink data={newData.previouseWinners} />
            )}
          </div>
        )}
        <div className="container-fluid py-3" id="stories">
          {newData && newData.showStoryCarousel && (
            <div>
              <h1 className="padding-left-8 padding-right-8 carousel-heading pt-1">
                Stories
              </h1>
              <div className="pb-5">
                {stories && stories.length > 0 && (
                  <Storycarousel data={stories} />
                )}
              </div>
            </div>
          )}
        </div>

        {newData && newData.showAccordion && (
          <div>
            {newData.Accordion && <Accordion data={newData.Accordion} />}
          </div>
        )}
        {newData && newData.CtaButton && (
          <div
            className="container-fluid bottom-div mt-0"
            style={{ background: newData.CtaButton.bgColor }}
          >
            <div className="col-12 py-4 d-flex justify-content-center">
              <AnchorLink
                to={newData.CtaButton.link}
                className="end-div"
                onAnchorLinkClick={handleClick}
              >
                {newData.CtaButton.title}
              </AnchorLink>
            </div>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};
