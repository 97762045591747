import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .year-selected {
    font-size: 16px;
    font-weight: 800;
  }

  .year-selected:hover {
    border-bottom: 2px solid #f2b542;
    cursor: pointer;
  }

  .underline-year {
    border-bottom: 2px solid #f2b542;
  }

  .img-class {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    @media (max-width: 600px) {
      height: 300px;
    }
    padding-bottom: 30px;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .image {
    display: block;
    width: ${props => props.theme["percent-100"]};
    height: auto;
  }
  .overlay {
    position: absolute;
    top: ${props => props.theme["zero"]};
    bottom: ${props => props.theme["zero"]};
    left: 0px;
    right: ${props => props.theme["zero"]};
    height: ${props => props.theme["percent-100"]};
    width: 100%;
    opacity: ${props => props.theme["zero"]};
    transition: 0.2s ease;
    background-color: ${props => props.theme["borderBottomColor"]};
  }
  .container:hover .overlay {
    opacity: 1;
  }
  .text {
    color: ${props => props.theme["primaryColor"]};
    font-size: ${props => props.theme["externalLinkSize"]};
    position: absolute;
    top: ${props => props.theme["percent-50"]};
    left: ${props => props.theme["percent-50"]};
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 95%;
  }

  .upreach-employee-img {
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 400px;
    @media (min-width: 1025px) and (max-width: 1440px) {
      max-height: 350px;
    }

    @media (min-width: 1441px) {
      max-height: 500px;
    }

    @media (max-width: 600px) {
      max-height: 300px;
    }
  }

  .team-names,
  .team-title {
    font-weight: bold;
    font-size: 18px;
  }

  .team-description {
    font-size: 14px;
  }

  .padding-sides {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1px;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 32px;
  }

  .container {
    position: relative;
    width: ${props => props.theme["percent-100"]};
  }
  .text-desc {
    font-size: 0.9rem;
  }
`;

const ImageLink = ({ data }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState([]);
  const [yearSelected, setYearSelected] = useState(0);
  useEffect(() => {
    const uniqueYears = [...new Set(data.map(item => item.year))].sort((a, b) => a - b);
    setYear(uniqueYears);
  }, [data]);
  useEffect(() => {
    const curYear = year.includes(currentYear) ? currentYear : year[year.length - 1];
    setYearSelected(curYear);
  }, [year, currentYear]);

  const mainUrl = process.env.GATSBY_API_URL;

  return (
    <StyledWrapper className="padding-left-8 padding-right-8 py-5 white-background">
      <div className="row">
        {/* <div className="col-6">
          <h1 className="header-text">previous Award winners</h1>
        </div> */}
        <div className="col-12">
          <div className="d-flex justify-content-end mb-2">
          {year.includes(2018) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2018" ? "underline-year" : " ")
              }
              onClick={() => setYearSelected(2018)}
            >
             2018
            </div>
            : null
            }
            {year.includes(2019) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2019" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2019)}
            >
            2019
            </div>
            : null
            }
            {year.includes(2020) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2020" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2020)}
            >
            2020
            </div>
            : null
            }
            {year.includes(2021) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2021" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2021)}
            >
            2021
            </div>
            : null
            }
            {year.includes(2022) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2022" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2022)}
            >
              2022
            </div>
            : null
            }
            {year.includes(2023) ? 
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2023" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2023)}
            >
              2023
            </div>
            : null
            }
            {year.includes(2024) ?
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2024" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2024)}
            >
              2024
            </div>
            : null
            }
            {year.includes(2025) ? 
            <div
              className={
                "year-selected " +
                (yearSelected == "2025" ? "underline-year" : "")
              }
              onClick={() => setYearSelected(2025)}
            >
              2025 
            </div>
            : null
            }
          </div>
        </div>
      </div>
      <div className="row">
        {data
          .filter(item => yearSelected === item.year)
          .map(item => (
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="container padding-sides">
                <div>
                  {item.Image && (
                    <img
                      className="img-fluid upreach-employee-img mx-auto d-block"
                      src={mainUrl + item.Image.url}
                      alt=""
                    />
                  )}
                  <h5 className="mb-0 team-names mt-3 text-center">
                    <b>{item.name}</b>
                  </h5>
                  <p className="text-desc text-center">{item.description}</p>
                </div>

                <div className="overlay">
                  <div className="text px-2">
                    <p className="mb-0 team-description">{item.Introduction}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex justify-content-center"></div>
    </StyledWrapper>
  );
};
export default ImageLink;
